
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91issue_93PIS5pOeJZgMeta } from "/opt/buildhome/repo/packages/web/app/pages/[owner]/[repo]/[issue].vue?macro=true";
import { default as indexSyQqPVcUGGMeta } from "/opt/buildhome/repo/packages/web/app/pages/[owner]/[repo]/index.vue?macro=true";
import { default as indexStYgOBiscvMeta } from "/opt/buildhome/repo/packages/web/app/pages/index.vue?macro=true";
export default [
  {
    name: "owner-repo-issue",
    path: "/:owner()/:repo()/:issue()",
    component: () => import("/opt/buildhome/repo/packages/web/app/pages/[owner]/[repo]/[issue].vue")
  },
  {
    name: "owner-repo",
    path: "/:owner()/:repo()",
    component: () => import("/opt/buildhome/repo/packages/web/app/pages/[owner]/[repo]/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/packages/web/app/pages/index.vue")
  }
]