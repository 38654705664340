import revive_payload_client_gHdM8pUJJJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_hqa2gau2vg7nmu34tmwjdeoakm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Mulh05xxUT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_hqa2gau2vg7nmu34tmwjdeoakm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8UP8D63Pe2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_hqa2gau2vg7nmu34tmwjdeoakm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_sP5leObt98 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_hqa2gau2vg7nmu34tmwjdeoakm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OxzW0dbp4l from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_hqa2gau2vg7nmu34tmwjdeoakm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3BgcNJqbc0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_hqa2gau2vg7nmu34tmwjdeoakm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_cRwKclwtmV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_hqa2gau2vg7nmu34tmwjdeoakm/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_Hlc335WZZl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_hqa2gau2vg7nmu34tmwjdeoakm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/opt/buildhome/repo/packages/web/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_MlTrEE6qtq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_hqa2gau2vg7nmu34tmwjdeoakm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_RAHspWIKy3 from "/opt/buildhome/repo/packages/web/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
export default [
  revive_payload_client_gHdM8pUJJJ,
  unhead_Mulh05xxUT,
  router_8UP8D63Pe2,
  payload_client_sP5leObt98,
  navigation_repaint_client_OxzW0dbp4l,
  check_outdated_build_client_3BgcNJqbc0,
  view_transitions_client_cRwKclwtmV,
  chunk_reload_client_Hlc335WZZl,
  components_plugin_zlvi6dcIsi,
  prefetch_client_MlTrEE6qtq,
  unocss_RAHspWIKy3
]